import {
  Box,
  Button,
  Chip,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import loader from "../../Assets/Images/loader.svg";
import { useEffect, useMemo, useState } from "react";
import { GetUserDetails, LoaderHide, LoaderShow } from "../../Helpers/Utility";
import { toast } from "react-toastify";
import { getAPI, postAPI } from "../../Services/Api";
import DescriptionIcon from "@mui/icons-material/Description";
import CustomPagination from "../../Components/CustomPagination/CustomPagination";
import NodataFound from "../../Components/NodataFound/NodataFound";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { EMPLOYEE_ROLE } from "../../Helpers/roles";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";
import deleteVec from "../../Assets/Images/deleteVector.png";
import close from "../../Assets/Images/cancel-circle-half-dot.png";
import attachment from "../../Assets/Images/document-attachment.png";
import { useNavigate } from "react-router-dom";
import UpdateDisputeModal from "./UpdateDisputeModal";
import RejectDisputeModal from "./RejectDisputeModal";
import Markdown from "markdown-to-jsx";
import ReviewDialog from "./ReviewDialog";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

   &:disabled {
    border-color: #aeaeae;
    color: #aeaeae;
    font-size: 17px;
  }



  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const STATUS = [
  "In Progress",
  "Resolved",
  "Closed",
  "Manually Resolved",
  "Withdraw",
  "Pending",
  "Accept",
  "Rejected",
  "Partially Resolved",
  "Under monitoring",
  "Resolved by admin",
  "Rejected by admin",
];

export default function AddDispute({
  project,
  path,
  dispute,
  isShowing,
  onBack,
  userBalance,
  getBalance,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFocused, setIsFocused] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const [milestoneList, setMilestoneList] = useState([]);

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [projectName, setProjectName] = useState("");
  const [milestone, setMilestone] = useState("");
  const [amount, setAmount] = useState("");
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");

  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (isShowing) {
      const userId = GetUserDetails()?.userId;
      if (
        dispute?.raisedBy?._id == userId &&
        dispute?.status == "Manually Resolved" &&
        !isNaN(dispute?.agreedAmount)
      ) {
        setOpenConfirmation(true);
      }
      if (dispute?.raisedBy?._id == userId && dispute?.status == "Rejected") {
        setOpenRejectModal(true);
      }
      if (
        dispute?.raisedBy?._id == userId &&
        [
          "Accept",
          "Resolved",
          "Partially Resolved",
          "Resolved by admin",
          "Closed",
        ].includes(dispute?.status) &&
        !dispute?.reviewSubmitted
      ) {
        setOpenReviewModal(true);
      }
      if (
        dispute?.against?._id == userId &&
        ["Rejected by admin"].includes(dispute?.status) &&
        !dispute?.reviewSubmitted
      ) {
        setOpenReviewModal(true);
      }
      setMilestoneList([dispute?.milestoneId]);
      setMilestone(dispute?.milestoneId?._id);
      setName(
        dispute?.against?._id == userId
          ? dispute?.raisedBy?.firstName
          : dispute?.against?.firstName
      );
      setAmount(dispute?.amount);
      setProjectName(dispute?.projectId?.title);
      setTitle(dispute?.title);
      setReason(dispute?.reason);
    } else {
      if (project._id) {
        getMilestoneList();
        if (GetUserDetails().role.includes(EMPLOYEE_ROLE))
          setName(project?.user?.firstName);
        else setName(project?.employee?.name);
        setProjectName(project?.title);
      }
    }
  }, [project, dispute, isShowing]);

  const confirmData = async (status) => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const userId = GetUserDetails()?.userId;
      if (token) {
        const res = await postAPI(
          "dispute/confirm",
          {
            userId: userId,
            action: status,
            id: dispute._id,
          },
          token
        );
        if (res.statusCode == 200) {
          toast.success(t(`Dispute proposal ${status}ed successfully.`));
          window.location.reload(path);
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  const isGuilty = useMemo(() => {
    const userId = GetUserDetails()?.userId;
    return dispute?.against?._id == userId;
  }, [isShowing, dispute]);

  const givenStatus = useMemo(() => {
    if (isGuilty) {
      if (dispute?.status == "Pending")
        return [
          { value: "inPrg", status: "In Progress" },
          { value: "resMut", status: "Manually Resolved" },
          { value: "rejdis", status: "Rejected" },
          { value: "acdis", status: "Accept" },
        ];
      if (dispute?.status == "In Progress")
        return [
          { value: "resMut", status: "Manually Resolved" },
          { value: "rejdis", status: "Rejected" },
          { value: "acdis", status: "Accept" },
        ];
    } else {
      if (dispute?.status == "Pending")
        return [
          { value: "withdis", status: "Withdraw" },
          // { value: "Resolve Dispute", status: "Resolved by complainant" },
        ];
      if (dispute?.status == "In Progress")
        return [
          { value: "withdis", status: "Withdraw" },
          // { value: "Resolve Dispute", status: "Resolved by complainant" },
        ];
      return false;
    }
  }, [dispute, isGuilty]);

  useEffect(() => {
    if (!isShowing) {
      const selectedMilestone = milestoneList.find(
        (ele) => ele._id == milestone
      );
      if (selectedMilestone?.status == "paid") {
        setAmount(selectedMilestone?.amountPaid || "");
      } else {
        setAmount(selectedMilestone?.amount || "");
      }
    }
  }, [milestone]);

  const getMilestoneList = async () => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const userId = GetUserDetails()?.userId;
      const roles = GetUserDetails()?.role;
      if (token) {
        const res = await postAPI(
          `milestone/dispute/${userId}`,
          {
            projectId: project._id,
            isEmployee: roles.includes(EMPLOYEE_ROLE).toString(),
          },
          token
        );
        if (res?.success) {
          setMilestoneList(res?.data);
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  const validateForm = () => {
    let isValid = true;
    let err = {};
    if (!title.trim()) {
      isValid = false;
      err = { ...err, title: t("enterTitle") };
    }

    if (!milestone.trim()) {
      isValid = false;
      err = { ...err, milestone: t("selectMilestone") };
    }

    if (isNaN(amount) || amount <= 0) {
      isValid = false;
      err = { ...err, amount: t("amtgreaterThan0") };
    } else if (milestone) {
      const selectedMilestone = milestoneList.find(
        (ele) => ele._id == milestone
      );
      if (
        selectedMilestone &&
        selectedMilestone?.status == "paid" &&
        amount > selectedMilestone?.amountPaid
      ) {
        isValid = false;
        err = {
          ...err,
          amount: `${t("plzentergrtMilestoneAmt")} ${
            selectedMilestone?.amountPaid
          }`,
        };
      }
    }
    if (!reason.trim()) {
      isValid = false;
      err = { ...err, reason: t("enterReason") };
    }

    setError(err);
    return isValid;
  };

  // Handle input focus
  const handleFocus = () => {
    // Logic when input is focused
    validateForm();
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    if (!validateForm()) return;
    setOpen(true);
  };

  const addDispute = async (ev) => {
    LoaderShow();
    try {
      const token = GetUserDetails()?.token;
      const userId = GetUserDetails()?.userId;
      if (token) {
        const data = {
          raisedBy: userId,
          against: GetUserDetails().role.includes(EMPLOYEE_ROLE)
            ? project?.user?._id
            : project?.employee?._id,
          title: title,
          reason: reason,
          projectId: project._id,
          milestoneId: milestone,
          amount: Number(amount),
        };

        const res = await postAPI("dispute/create", data, token);
        if (res?.statusCode == 200) {
          toast.success(t("toast37"));
          navigate(path);
        }
      }
    } catch (err) {
      console.log(t(err.message));
      toast.error(t(err.message));
    }
    LoaderHide();
  };

  const onStatusChange = (status) => {
    setNewStatus(status);
    if (status == "Rejected") {
      setOpenRejectModal(true);
    } else {
      setOpenStatusModal(true);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <Box component="form" sx={{ m: 3 }} onSubmit={onSubmit}>
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                mb: 2,
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {t("TitleContact")}
                </FormLabel>
                <TextField
                  disabled={isShowing}
                  placeholder={t("TitleContact")}
                  onChange={(ev) => {
                    setTitle(ev.target.value);
                    setError((err) => ({
                      ...err,
                      title: ev.target.value ? "" : t("enterTitle"),
                    }));
                  }}
                  value={title}
                  error={!!error.title}
                  helperText={error.title}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  fullWidth
                  required
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                mb: 2,
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {!isShowing
                    ? GetUserDetails().role.includes(EMPLOYEE_ROLE)
                      ? t("Employer")
                      : t("Employee")
                    : ""}{" "}
                  {t("name")}
                </FormLabel>
                <TextField
                  disabled
                  onChange={(ev) => {
                    setName(ev.target.value);
                    setError((err) => ({
                      ...err,
                      name: ev.target.value ? "" : "Please enter name.",
                    }));
                  }}
                  value={name}
                  error={!!error.name}
                  helperText={error.name}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  fullWidth
                  placeholder={
                    GetUserDetails().role.includes(EMPLOYEE_ROLE) ? (
                      <>
                        {t("Employer")} {t("name")}
                      </>
                    ) : (
                      <>
                        {t("Employee")} {t("name")}
                      </>
                    )
                  }
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {t("project")}
                </FormLabel>
                <TextField
                  disabled
                  onChange={(ev) => {
                    setProjectName(ev.target.value);
                    setError((err) => ({
                      ...err,
                      projectName: ev.target.value ? "" : t("enterPRoj"),
                    }));
                  }}
                  value={projectName}
                  error={!!error.projectName}
                  helperText={error.projectName}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  fullWidth
                  placeholder="Project"
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  required
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                mb: 2,
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {t("Milestone")}
                </FormLabel>
                <Select
                  disabled={isShowing}
                  onChange={(ev) => {
                    setMilestone(ev.target.value);
                    setError((err) => ({
                      ...err,
                      milestone: ev.target.value ? "" : t("selectMilestone"),
                    }));
                  }}
                  value={milestone}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  fullWidth
                  placeholder={t("Milestone")}
                >
                  <MenuItem value="">{t("Select")}</MenuItem>
                  {milestoneList.map((milestone) => (
                    <MenuItem value={milestone?._id}>
                      {milestone?.title}
                    </MenuItem>
                  ))}
                </Select>
                {error.milestone && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      lineHeight: "1.66",
                      letterSpacing: "0.0333em",
                    }}
                  >
                    {error.milestone}
                  </span>
                )}
              </Box>
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {t("disputeAmt")}
                </FormLabel>
                <TextField
                  disabled={isShowing}
                  type="number"
                  min={1}
                  onChange={(ev) => {
                    setAmount(ev.target.value);
                    setError((err) => ({
                      ...err,
                      amount:
                        ev.target.value && ev.target.value > 0
                          ? ""
                          : t("amtgreaterThan0"),
                    }));
                  }}
                  value={amount}
                  error={!!error.amount}
                  helperText={error.amount}
                  variant="outlined"
                  sx={{
                    background: isFocused ? "#FFFFFF" : "transparent",
                    borderColor: isFocused ? "#0B3013" : "#E0E0E0",
                  }}
                  fullWidth
                  placeholder={t("disputeAmt")}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  required
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                mb: 2,
                gap: 2,
              }}
            >
              <Box sx={{ flex: 1 }}>
                <FormLabel
                  sx={{ display: "block", mb: 1, color: "black", fontSize: 14 }}
                >
                  {t("reason")}
                </FormLabel>
                <Textarea
                  disabled={isShowing}
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    minHeight: "70px",
                  }}
                  maxRows={6}
                  fullWidth={true}
                  aria-label="maximum height"
                  placeholder={t("reason")}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  onChange={(ev) => {
                    setReason(ev.target.value);
                    setError((err) => ({
                      ...err,
                      reason: ev.target.value ? "" : t("enterReason"),
                    }));
                  }}
                  value={reason}
                  rows={4}
                  required
                />
                {error.reason && (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontSize: "0.75rem",
                      lineHeight: "1.66",
                      letterSpacing: "0.0333em",
                    }}
                  >
                    {error.reason}
                  </span>
                )}
              </Box>
            </Box>
            {isShowing && givenStatus && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  mb: 2,
                  gap: 2,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <FormLabel
                    sx={{
                      display: "block",
                      mb: 1,
                      color: "black",
                      fontSize: 18,
                    }}
                  >
                    {t("setStatus")}
                  </FormLabel>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      gap: 2,
                    }}
                  >
                    {givenStatus.map((ele) => (
                      <Button
                        onClick={(ev) => onStatusChange(ele.status)}
                        sx={{
                          fontFamily: "inherit",
                          px: 4,
                          fontSize: "16px !important",
                          pb: 1,
                          fontWeight: 400,
                          borderRadius: 2,
                          backgroundColor: "#0B3013",
                          width: "auto",
                          maxWidth: "fit-content",
                          textTransform: "capitalize",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center", // Align icon vertically with text
                          "&:hover": {
                            backgroundColor: "#0B3013", // Keep the same color as default
                          },
                        }}
                      >
                        {t(ele.value)}
                      </Button>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ mt: 4 }}>
            {dispute?.rejection?.responseFromAgainst?.reason && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 2,
                  gap: 2,
                }}
              >
                <Typography
                  variant={"h6"}
                  sx={{ fontWeight: "bold", mt: 1, fontSize: "23px" }}
                >
                  {GetUserDetails()?.userId == dispute?.against?._id
                    ? t("resByU")
                    : t("resByDef")}
                </Typography>
                <Box sx={{ flex: 1 }}>
                  <FormLabel
                    sx={{
                      display: "block",
                      mb: 1,
                      color: "black",
                      fontSize: 14,
                    }}
                  >
                    {t("reason")}
                  </FormLabel>
                  <Box
                    sx={{
                      // width: "100%",
                      border: "1px solid #b9b9b9",
                      p: 1.5,
                      borderRadius: 1,
                      minHeight: "150px",
                    }}
                  >
                    <Markdown>
                      {dispute?.rejection?.responseFromAgainst?.reason}
                    </Markdown>
                  </Box>
                </Box>
                {dispute?.rejection?.responseFromAgainst?.proofs?.length >
                  0 && (
                  <>
                    <FormLabel
                      sx={{
                        display: "block",
                        color: "black",
                        fontSize: 18,
                        fontWeight: "bold",
                        mb: "-10px",
                      }}
                    >
                      {t("Attachments")}
                    </FormLabel>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "7px",
                      }}
                    >
                      {dispute?.rejection?.responseFromAgainst?.proofs.map(
                        (file, index) => (
                          <Typography
                            key={index}
                            variant="p"
                            sx={{
                              color: "#000",
                              px: 1,
                              display: "flex",
                              gap: "7px",
                            }}
                          >
                            {/* {file.name} ({file.size / 1024}kB) */}
                            <img src={attachment} alt="atch" />
                            <a target="_blank" href={file?.url}>
                              {file?.url}
                            </a>
                          </Typography>
                          //  {fileNames.map((name, index) => (
                          //   <div key={index}>{name}</div>
                          // ))}
                        )
                      )}
                    </Box>
                  </>
                )}
              </Box>
            )}
            {dispute?.rejection?.responseFromRaisedBy?.reason && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 2,
                  gap: 2,
                }}
              >
                <Typography
                  variant={"h6"}
                  sx={{ fontWeight: "bold", mt: 1, fontSize: "23px" }}
                >
                  {GetUserDetails()?.userId == dispute?.raisedBy?._id
                    ? t("resByU")
                    : t("resByCom")}
                </Typography>
                <Box sx={{ flex: 1 }}>
                  <FormLabel
                    sx={{
                      display: "block",
                      mb: 1,
                      color: "black",
                      fontSize: 14,
                    }}
                  >
                    {t("reason")}
                  </FormLabel>
                  <Box
                    sx={{
                      // width: "100%",
                      border: "1px solid #b9b9b9",
                      p: 1.5,
                      borderRadius: 1,
                      minHeight: "150px",
                    }}
                  >
                    <Markdown>
                      {dispute?.rejection?.responseFromRaisedBy?.reason}
                    </Markdown>
                  </Box>
                </Box>
                {dispute?.rejection?.responseFromRaisedBy?.proofs?.length >
                  0 && (
                  <>
                    <FormLabel
                      sx={{
                        display: "block",
                        color: "black",
                        fontSize: 18,
                        fontWeight: "bold",
                        mb: "-10px",
                      }}
                    >
                      {t("Attachments")}
                    </FormLabel>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "7px",
                      }}
                    >
                      {dispute?.rejection?.responseFromRaisedBy?.proofs.map(
                        (file, index) => (
                          <Typography
                            key={index}
                            variant="p"
                            sx={{
                              color: "#000",
                              px: 1,
                              display: "flex",
                              gap: "7px",
                            }}
                          >
                            {/* {file.name} ({file.size / 1024}kB) */}
                            <img src={attachment} alt="atch" />
                            <a target="_blank" href={file?.url}>
                              {file?.url}
                            </a>
                          </Typography>
                          //  {fileNames.map((name, index) => (
                          //   <div key={index}>{name}</div>
                          // ))}
                        )
                      )}
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "", mt: 4 }}>
            {!isShowing ? (
              <Button
                type="submit"
                sx={{
                  textTransform: "capitalize",
                  my: 2,
                  bgcolor: "#0B3013",
                  fontSize: 16,
                  color: "#fff",
                  borderRadius: 10,
                  px: 4,
                  py: 1,
                  fontWeight: 400,
                  "&:hover": {
                    bgcolor: "#0B3013",
                  },
                }}
                endIcon={<ArrowOutwardIcon color="#fff" />}
              >
                {t("fileDispute")}
              </Button>
            ) : (
              <Button
                onClick={onBack}
                sx={{
                  textTransform: "capitalize",
                  my: 2,
                  bgcolor: "#0B3013",
                  fontSize: 16,
                  color: "#fff",
                  borderRadius: 10,
                  px: 4,
                  py: 1,
                  fontWeight: 400,
                  "&:hover": {
                    bgcolor: "#0B3013",
                  },
                }}
                endIcon={<ArrowOutwardIcon color="#fff" />}
              >
                {t("BackButton")}
              </Button>
            )}
          </Box>
        </Box>
      </TableContainer>
      {/* Delete Confirmation Modal */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 4,
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="Close" />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              <img className="deleteVec" src={deleteVec} alt="Delete Vector" />
            </Box>
            <Typography
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("areUsureCon")}
            </Typography>
            <Typography
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              {t("sctionSus")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
              onClick={addDispute}
            >
              {t("proceed")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: 4,
          }}
        >
          <Box className="SubHeader">
            <Typography
              variant="h5"
              id="subscribe-modal-title"
              sx={{
                borderBottom: 1,
                py: 2,
                pt: 0,
                borderColor: "#E6E6E6",
              }}
              gutterBottom
            >
              {t("mutRes")}
            </Typography>
          </Box>
          <IconButton
            onClick={() => setOpenConfirmation(false)}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <img src={close} alt="Close" />
          </IconButton>
          <Box sx={{ mt: 2 }}>
            {/* <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              <img className="deleteVec" src={deleteVec} alt="Delete Vector" />
            </Box> */}
            {/* <Typography
              id="logout-modal-description"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 16,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              Are you sure you want to continue?
            </Typography> */}
            <Typography
              id="logout-modal-description"
              variant="body1"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              {dispute?.against?.firstName} {t("haseSentPro")} $
              {dispute?.agreedAmount}. {t("whtydoing")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              variant="contained"
              onClick={() => confirmData("reject")}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("rejct")}
            </Button>
            <Button
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
              onClick={(ev) => confirmData("accept")}
            >
              {t("accpt")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <UpdateDisputeModal
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        dispute={dispute}
        newStatus={newStatus}
        onSave={onBack}
        userBalance={userBalance}
        getBalance={getBalance}
      />

      <RejectDisputeModal
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        dispute={dispute}
      />

      <ReviewDialog
        open={openReviewModal}
        onClose={() => setOpenReviewModal(false)}
        dispute={dispute}
        onSubmit={onBack}
      />
    </>
  );
}
