import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Menu,
  Typography,
  IconButton,
  Rating,
  InputBase,
  Accordion,
  FormLabel,
  Modal,
  AccordionSummary,
  AccordionDetails,
  Radio,
  useMediaQuery,
  FormControl,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Select,
} from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarIcon from "@mui/icons-material/Star";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { styled, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import "../../../Localization/i18n";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import BrowseJobsBanner from "../../../Assets/Images/BrowseJobsBanner.png";
import AvatarJob from "../../../Assets/Images/BrowseJobsAvatar.png";
import chatIco from "../../../Assets/Images/ChatIco.png";
import dollarIco from "../../../Assets/Images/dollar-circle.png";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import loader from "../../../Assets/Images/loader.svg";
import { useNavigate } from "react-router-dom";
import {
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import indiaIco from "../../../Assets/Images/indiaFlag.png";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
// import FilterModal from "./FilterModal/FilterModal";
import NodataFound from "../../../Components/NodataFound/NodataFound.jsx";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import Dropdown from "../../../Components/Dropdown/Dropdown.jsx";
import { countries } from "../../../Helpers/common_constants.js";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import Markdown from "markdown-to-jsx";

const StyledAccordion = styled(Accordion)({
  boxShadow: "none", // Remove box shadow from accordion
});
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    backgroundColor: "#F7F7F7",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const labels = {
  0.5: "0.5",
  1: "1.0",
  1.5: "1.5",
  2: "2.0",
  2.5: "2.5",
  3: "3.0",
  3.5: "3.5",
  4: "4.0",
  4.5: "4.5",
  5: "5.0",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

export default function EmployeerBrowseProfileDashboard() {
  const [expanded, setExpanded] = useState("panel1"); // Set the first panel to be expanded by default

  const [anchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [rateType, setRateType] = useState(""); // Define rateType state
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const [isFavorite, setIsFavorite] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(t("Choose"));
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [isfilterApplied, setIsFilterApplied] = useState(false);
  const [isInSearch, setIsInSearch] = useState(false);
  const [showCustomPagination, setShowCustomPagintaion] = useState(true);

  const handleFilterModalOpen = () => {
    setExpanded("panel1");
    if (!isfilterApplied) {
      setRateType("");
      setFormData({
        budget: "",
        location: "",
      });
    }
    setFilterModalOpen(true);
  };

  const handleFilterModalClose = () => {
    setFilterModalOpen(false);
  };
  const [formData, setFormData] = useState({
    budget: "",
    location: "",
  });

  const handleBudget = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      budget: value,
    }));
  };

  const handleLocation = (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      location: value,
    }));
  };

  const budgetValues = [100, 200, 300, 400, 500, 600, 700, 800, 900];

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (category) => {
    // if (category) {
    //   setSelectedCategory(category);
    //   getFilteredEmployeeList(category);
    // }
    handleFilterModalClose();
    if (isfilterApplied) {
      await getProjectList();
      setIsFilterApplied(false);
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    getProjectList();
    LoaderShow();
    getProjectData();
  }, []);

  const [dropValues, setDropValues] = useState();

  const getProjectData = async () => {
    try {
      LoaderShow();
      const response = await getAPI("project/dropdown");
      if (response.statusCode === 200) {
        setDropValues(response?.data);
        LoaderHide();
      }
    } catch (error) {
      LoaderHide();
      console.log("error===", error);
    }
  };

  const navigate = useNavigate();

  const onfilterClicked = async () => {
    setIsFilterApplied(true);
    handleFilterModalClose();
    setShowCustomPagintaion(false);
    getProjectList();
    setShowCustomPagintaion(true);
  };

  const getProjectList = async (
    pageNumber = 1,
    keyword = query,
    paymentType = rateType,
    estimatedBudget = formData?.budget,
    location = formData?.location
  ) => {
    LoaderShow();
    try {
      LoaderShow();
      const token = GetUserDetails().token;

      var pn;
      if (pageNumber === undefined || pageNumber === "") {
        pn = 1;
      } else {
        pn = pageNumber;
      }

      const response = await getAPI(
        `project/all-filter?page=${pn}&keyword=${keyword || ""}&paymentType=${(
          paymentType || ""
        ).replace(/ /g, "%20")}&estimatedBudget=${
          estimatedBudget || ""
        }&location=${location || ""}`,
        token
      );
      if (response.statusCode === 200) {
        LoaderHide();
        setTotalPages(response?.totalPages);
        setProjectList(response.data);
      }
    } catch (error) {
      LoaderHide();
      // toast.error(error.message)
    }
    LoaderHide();
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getProjectList(value);
  };

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  const handleRateTypeChange = (event) => {
    setRateType(event.target.value);
  };

  const navigateToPraposeEmployer = (user) => {
    navigate("/reviewratings", {
      state: {
        user,
      },
    });
  };

  const [query, setQuery] = useState("");

  const onSearchKeyDown = (ev) => {
    if (ev.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    if (!query.trim()) {
      return;
    }
    setShowCustomPagintaion(false);
    getProjectList();
    setIsInSearch(true);
    setShowCustomPagintaion(true);
  };

  const handleAllClear = () => {
    setQuery("");
    document.getElementById("search-query").value = "";
    setFormData({
      perHourAmount: "",
      location: "",
      rateType: "",
    });
    if (isInSearch || isfilterApplied) {
      setShowCustomPagintaion(false);
      getProjectList(1, "", "", "", "");
      setShowCustomPagintaion(true);
    }
    setIsInSearch(false);
    setIsFilterApplied(false);
  };

  const getFilteredEmployeeList = async (filters, filterType) => {
    try {
      LoaderShow();

      let dataToSend;

      if (filterType === "paymentType") {
        dataToSend = {
          paymentType: filters,
        };
      } else if (filterType === "estimatedBudget") {
        dataToSend = {
          estimatedBudget: filters,
        };
      } else if (filterType === "location") {
        dataToSend = {
          location: filters,
        };
      }

      const response = await postAPI("project/filter?page=1", dataToSend);
      if (response.statusCode === 200) {
        LoaderHide();
        setIsFilterApplied(true);
        setProjectList(response.data);
        setTotalPages(response?.pagination?.totalPages);
        handleFilterModalClose();
      }
    } catch (error) {
      LoaderHide();
      handleFilterModalClose();
      // toast.error(error?.message);
      setProjectList([]);
      // Handle error (e.g., show a toast message)
      console.error("Error fetching filtered employees:==", error);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [bookmarkedProjects, setBookmarkedProjects] = useState([]);

  useEffect(() => {
    // Assuming each project has an `isBookmarked` property to initialize the state
    const initialBookmarks = projectList?.map(
      (project) => project.isBookmarked
    );
    setBookmarkedProjects(initialBookmarks);
  }, [projectList]);

  const handleBookmarkClick = async (project, index) => {
    LoaderShow();

    const isBookmarked = bookmarkedProjects[index];
    const updatedBookmarks = [...bookmarkedProjects];
    updatedBookmarks[index] = !isBookmarked;
    setBookmarkedProjects(updatedBookmarks);

    const apiEndpoint = isBookmarked
      ? "project/removebookmark"
      : "project/bookmark";

    LoaderShow();

    try {
      const token = GetUserDetails().token;
      const dataToSend = { projectId: project?._id };
      const response = await postAPI(apiEndpoint, dataToSend, token);
      if (response.statusCode === 200) {
        toast.success(t(response.message));
        LoaderHide();
      } else {
        toast.error(t(response.message));
        LoaderHide();
      }
    } catch (error) {
      toast.error(error.message);
      LoaderHide();
    }
    LoaderHide();
  };

  const sendProjectDetails = (project) => {
    navigate("/dashboard/employee/projectdetails", {
      state: {
        project,
      },
    });
  };

  const handleSearchClear = () => {
    if (query) {
      setQuery("");
      document.getElementById("search-query").value = "";
      if (isInSearch) {
        setShowCustomPagintaion(false);
        getProjectList(1, "");
        setShowCustomPagintaion(true);
      }
      setIsInSearch(false);
    }
  };

  return (
    <section className="messageEmployee">
      <div id="hideloding" className="loding-display">
        <img src={loader} alt="loader-img" />
      </div>
      <Box sx={{}}>
        <Typography variant="h4" sx={{ pb: 1, fontWeight: 600 }}>
          {t("BrowseProject")}
        </Typography>
        <Typography
          className="priceHeadEmployee"
          variant="p"
          sx={{ fontSize: 18, color: "#777" }}
        ></Typography>
      </Box>
      <Box sx={{ py: 5 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap"
          }}
        >
          <Box>
            <InputBase
              id="search-query"
              placeholder={t("SearchText")}
              startAdornment={<SearchIcon sx={{ fontSize: 25 }} />}
              endAdornment={
                query.length > 0 ? (
                  <CloseIcon
                    onClick={() => handleSearchClear()}
                    sx={{ fontSize: 25, cursor: "pointer" }}
                  />
                ) : (
                  ""
                )
              }
              classes={{ input: "noFocusBorder" }}
              onChange={(e) => setQuery(e.target.value)}
              onKeyUp={onSearchKeyDown}
              sx={{
                FontFace: "inherit",
                backgroundColor: "#fff",
                border: 0,
                borderRadius: 5,
                padding: "8px 12px",
                width: "400px",
                maxWidth: "85vw",
              }}
            />
            <Button
              sx={{
                bgcolor: "#0a3114",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                px: 5,
                py: 1,
                ml: 2,
                mb: 3,
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
              onClick={handleSearch}
            >
              {t("SearchText")}
            </Button>
            <Button
              sx={{
                bgcolor: "#0a3114",
                fontSize: 16,
                color: "#fff",
                borderRadius: 10,
                py: 1,
                ml: 2,
                mb: 3,
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
              onClick={handleAllClear}
            >
              <RefreshIcon />
            </Button>
          </Box>
          <Box>
            <Button
              onClick={handleFilterModalOpen}
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                textTransform: "capitalize",
                bgcolor: "#0a3114",
                fontSize: 17,
                color: "#fff",
                borderRadius: 10,
                px: 4,
                py: 1,
                "&:hover": {
                  bgcolor: "#0a3114",
                },
              }}
              startIcon={<FilterAltOutlinedIcon color="#fff" />}
            >
              {t("Filter")}
            </Button>{" "}
          </Box>
        </Box>
      </Box>
      <Grid item md={8} lg={8} xl={8} xs={12}>
        <Box>
          <Box>
            <Box
              sx={{
                // backgroundColor: "#fff",
                py: 3,
                borderRadius: 3,
              }}
            >
              <Box
                className="filterTop"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2.5,
                  pt: 0,
                  pb: 1.5,
                  justifyContent: "space-between",
                }}
              ></Box>

              <Grid item md={4} lg={8} xl={8}>
                <Box>
                  <Box>
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        py: 3,
                        borderRadius: 10,
                        border: 1,
                        borderColor: "#e3e3e3",
                      }}
                    >
                      <Box
                        className="filterTop"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          // borderBottom: 1,
                          // borderColor: "#dadbda",
                          p: 2.5,
                          pt: 0,

                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h6"
                            className="langText  "
                            sx={{ fontWeight: 500, color: "#000" }}
                          >
                            {t("Top")}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            className="langText "
                            variant="p"
                            sx={{
                              color: "#000",
                              fontSize: 17,
                              fontWeight: 500,
                              display: "block",
                              px: 2,
                            }}
                          >
                            {/* Sort by */}
                          </Typography>
                          {/* <div>
                            <Button
                              className="filterDrop"
                              sx={{
                                backgroundColor: "#F7F7F7!important",
                                color: "#000",
                                fontSize: 17,
                                textTransform: "capitalize",
                                borderRadius: 12,
                              }}
                              id="demo-customized-button1"
                              aria-controls={
                                open ? "demo-customized-menu1" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              variant="contained"
                              disableElevation
                              onClick={handleClick}
                              endIcon={<KeyboardArrowDownIcon />}
                            >
                              Latest
                            </Button>
                            <StyledMenu
                              id="demo-customized-menu1"
                              MenuListProps={{
                                "aria-labelledby": "demo-customized-button1",
                              }}
                              anchorEl={anchorEl1}
                              open={open1}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={handleClose} disableRipple>
                                Default
                              </MenuItem>
                              <MenuItem onClick={handleClose} disableRipple>
                                Duplicate
                              </MenuItem>
                            </StyledMenu>
                          </div> */}
                        </Box>
                      </Box>

                      {/* New Project Details Starts */}

                      {projectList?.length === 0 ? (
                        <Box
                          sx={{
                            py: 2,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <NodataFound />
                        </Box>
                      ) : (
                        projectList?.map((project, index) => (
                          <>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => sendProjectDetails(project)}
                            >
                              <Box
                                className="employeeMainHead flexColumnRespo"
                                sx={{
                                  borderTop: 1,
                                  borderColor: "#dadbda",
                                  p: 2.5,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box>
                                  {/* <Link to="/projectdetails"> */}
                                  <Button>
                                    <Typography
                                      className="employeeHead"
                                      variant="h5"
                                      sx={{
                                        color: "#000",
                                        fontWeight: 500,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {project?.title}
                                    </Typography>
                                  </Button>
                                  {/* </Link> */}
                                </Box>
                                <Box
                                  className="priceHeadEmployee flexColumnRespo"
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography
                                    className="catBtnTop"
                                    variant="p"
                                    sx={{ color: "#222" }}
                                  >
                                    {/* 6 bids */}
                                  </Typography>
                                  <Typography
                                    className="priceHeadEmployee catBtnTop"
                                    variant="h5"
                                    sx={{
                                      color: "#000",
                                      px: 2,
                                      fontWeight: 500,
                                    }}
                                  >
                                    ${project?.budget?.min} - $
                                    {project?.budget?.max}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box sx={{ p: 2.5, pt: 0 }}>
                                <Typography
                                  className="budgetHeadEmployee"
                                  variant="p"
                                  sx={{
                                    fontWeight: 500,
                                    color: "#000",
                                    fontSize: 17,
                                  }}
                                >
                                  {/* Budget ${project?.budget?.min} - {project?.budget?.max} {project?.currency} */}
                                </Typography>
                              </Box>

                              <Box sx={{ p: 2.5, pt: 0 }}>
                                <Typography
                                  className="priceHeadEmployee markdown"
                                  variant="p"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 10 /* number of lines to show */,
                                    lineClamp: 10,
                                    WebkitBoxOrient: "vertical",
                                  }}
                                  sx={{
                                    fontWeight: 400,
                                    color: "#222",
                                    fontSize: 16,
                                  }}
                                >
                                  <Markdown>{project?.description}</Markdown>
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  p: 2.5,
                                  pt: 0,
                                  gap: "8px",
                                }}
                              >
                                {project?.skillsRequired?.map(
                                  (skill, index) => (
                                    <Box
                                      className="priceHeadEmployee"
                                      sx={{
                                        width: "auto",
                                        maxWidth: "fit-content",
                                        backgroundColor: "#F7FDF9",
                                        color: "#0B3013",
                                        p: 1.4,
                                        px: 3,
                                        my: 1,
                                        borderRadius: 20,
                                      }}
                                    >
                                      {skill?.name}
                                    </Box>
                                  )
                                )}
                              </Box>
                            </div>

                            <Box
                              className="flexColumnRespo"
                              sx={{
                                p: 3,
                                pt: 0,
                                pb: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Rating
                                  disabled
                                  name="hover-feedback"
                                  value={project?.user?.averageRating}
                                  precision={0.5}
                                  getLabelText={getLabelText}
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 0.55 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                {project?.user?.averageRating !== null && (
                                  <Box sx={{ ml: 2 }}>
                                    {project?.user?.averageRating?.toFixed(2)}
                                  </Box>
                                )}
                              </Box>

                              <Box className="catBtnTop">
                                <Typography
                                  variant="p"
                                  sx={{ color: "#0B3013" }}
                                >
                                  {/* 32 minutes ago */}
                                </Typography>

                                <IconButton
                                  onClick={() =>
                                    handleBookmarkClick(project, index)
                                  }
                                >
                                  {bookmarkedProjects[index] ? (
                                    <BookmarkIcon sx={{ color: "#FFB800" }} /> // Fill color when bookmarked
                                  ) : (
                                    <BookmarkBorderIcon
                                      sx={{ color: "#0B3013" }}
                                    /> // Default color when not bookmarked
                                  )}
                                </IconButton>
                              </Box>
                            </Box>
                          </>
                        ))
                      )}

                      {/* New Project Details Ends */}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box sx={{ py: 2, display: "flex", justifyContent: "center" }}>
          {projectList?.length > 0 ? (
            <CustomPagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            />
          ) : (
            ""
          )}
        </Box>
        {/* <FilterModal
          open={filterModalOpen}
          handleClose={handleFilterModalClose}
        /> */}
        <Modal
          open={filterModalOpen}
          onClose={handleFilterModalClose}
          aria-labelledby="logout-modal"
          aria-describedby="logout-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              width: isMobile ? "90%" : isDesktop ? 510 : "75%", // Adjust width for mobile and desktop devices
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
              borderRadius: 4,
            }}
          >
            <Typography variant="h5" sx={{ mb: 1, fontWeight: 500 }}>
              {t("Filter")}
            </Typography>
            <IconButton
              sx={{
                position: "absolute",
                top: 14,
                right: 10,
                pb: 4,
              }}
              onClick={handleFilterModalClose}
            >
              <img src={close} alt="Close" />
            </IconButton>
            <Box sx={{ borderTop: 1, borderColor: "#dededf" }}>
              <Box>
                <div>
                  <StyledAccordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography
                        variant="p"
                        sx={{ fontWeight: 500, color: "#000" }}
                      >
                        {t("ProjectType")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="rate-type"
                          name="rate-type"
                          value={rateType}
                          onChange={handleRateTypeChange}
                        >
                          <FormControlLabel
                            value="Hourly Rate"
                            control={
                              <Radio sx={{ color: "#0B3013!important" }} />
                            }
                            label={t("Hourly")}
                          />
                          <FormControlLabel
                            value="Fixed Price"
                            control={
                              <Radio sx={{ color: "#0B3013!important" }} />
                            }
                            label={t("Fixed")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography variant="p" sx={{ color: "#000" }}>
                        {t("EstimateBudget")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </Typography> */}
                      <Grid item xs={12} md={6}>
                        <Box>
                          <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1.4,
                              fontSize: 14,
                            }}
                          >
                            {t("BudgetOne")}
                          </FormLabel>
                          <Dropdown
                            value={formData.budget}
                            onChange={handleBudget}
                            options={dropValues?.estimatEnumValues?.map(
                              (value) => ({
                                value: value?._id,
                                label: `${value?.name}`,
                              })
                            )}
                            label="Price"
                          />
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>
                  <StyledAccordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      <Typography variant="p" sx={{ color: "#000" }}>
                        {t("Location")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse malesuada lacus ex, sit amet blandit leo
                        lobortis eget.
                      </Typography> */}
                      <Grid item xs={12} md={6}>
                        <Box>
                          {/* <FormLabel
                            sx={{
                              display: "block",
                              textAlign: "start",
                              py: 1.4,
                              fontSize: 14,
                            }}
                          >
                            Location
                          </FormLabel>
                          <Dropdown
                            value={formData.location}
                            onChange={handleLocation}
                            options={countries}
                            label="Location"
                          /> */}
                          <FormControl fullWidth>
                            <InputLabel id="gender-select-label">
                              {t("Country")}
                            </InputLabel>
                            <Select
                              labelId="gender-select-label"
                              id="gender-select"
                              value={formData?.location}
                              label={t("Country")}
                              onChange={handleLocation}
                            >
                              {countries?.map((option) => (
                                <MenuItem
                                  key={option.label}
                                  value={option.label}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </StyledAccordion>

                  <Box sx={{ my: 3, display: "flex" }}>
                    <Button
                      onClick={onfilterClicked}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                        textTransform: "capitalize",
                        bgcolor: "#0a3114",
                        fontSize: 16,
                        color: "#fff",
                        borderRadius: 10,
                        px: 5,
                        py: 1,
                        "&:hover": {
                          bgcolor: "#0a3114",
                        },
                      }}
                      endIcon={<ArrowOutwardIcon color="#fff" />}
                    >
                      {t("Filter")}
                    </Button>
                    <Button
                      onClick={handleClose}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                        textTransform: "capitalize",
                        bgcolor: "#0a3114",
                        fontSize: 16,
                        color: "#fff",
                        borderRadius: 10,
                        px: 5,
                        py: 1,
                        "&:hover": {
                          bgcolor: "#0a3114",
                        },
                      }}
                      endIcon={<ArrowOutwardIcon color="#fff" />}
                    >
                      {t("Clear")}
                    </Button>
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </section>
  );
}
