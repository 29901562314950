import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  useMediaQuery,
  Modal,
  Button,
  Rating,
  Grid,
  FormLabel,
  styled,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import Logo from "../../../Assets/Images/Logo.png";
import toggle from "../../../Assets/Images/SidebarToggle.png";
import Not from "../../../Assets/Images/notification-02.png";
import message from "../../../Assets/Images/message-01 (1).png";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";

import {
  getUrl,
  GetUserDetails,
  LoaderHide,
  LoaderShow,
} from "../../../Helpers/Utility";
import { getAPI, postAPI } from "../../../Services/Api";

import { useTheme } from "@emotion/react";
import logoutVect from "../../../Assets/Images/LogoutVector.png";
import close from "../../../Assets/Images/cancel-circle-half-dot.png";
import { useTranslation } from "react-i18next";
import CountryFlag from "../../../Components/CountryFlag/CountryFlag";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import { toast } from "react-toastify";
import { WindowSharp } from "@mui/icons-material";

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 4px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #E0E0E0;
    };




  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

export default function DashboardHeader({
  handleDrawerOpen,
  handleDrawerToggle,
}) {
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [userDetails, setUserDetails] = useState("");
  const notificationPanelRef = useRef(null); // Ref for notification panel

  // Review
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [selectedNotification, setselectedNotification] = useState({});
  const [valuerating, setValueRating] = React.useState("");
  const [errorMessages, setErrorMessages] = useState({
    textReview: "",
    valuerating,
  });
  const [text, setText] = useState({
    textReview: "",
  });

  useEffect(() => {
    getNotifications();
    getUserDetails();
    const removeNotif = () => {
      if (notificationOpen) setNotificationOpen(false);
    };
    document.addEventListener("click", removeNotif);
    // return () => document.removeEventListener(removeNotif);
    // Simulated initial notifications (replace with actual data fetching logic)
    // setNotifications([
    //   {
    //     id: 1,
    //     message:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     timestamp: "1 hour ago",
    //     read: false, // Marking the notification as unread
    //   },
    //   {
    //     id: 2,
    //     message:
    //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     timestamp: "2 hours ago",
    //     read: true, // Marking the notification as read
    //   },
    // ]);
  }, []);

  useEffect(() => {
    if (notificationOpen)
      localStorage.setItem(
        "seenNotification",
        JSON.stringify(notifications.map((ele) => ele._id))
      );
  }, [notificationOpen]);

  const isNotEmpty = (value) => value.trim() !== "";

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    if (!isNotEmpty(text.textReview) || text.textReview.length < 5) {
      errors.textReview = t("ReviewShouldbe5");
      isValid = false;
    }

    if (valuerating.length === 0) {
      errors.valuerating = t("PleaseGiveStar");
      isValid = false;
    }

    setErrorMessages(errors);
    return isValid;
  };

  const hasUnseenNotification = () => {
    if (!localStorage.getItem("seenNotification")) {
      return true;
    }
    const seenNotification = JSON.parse(
      localStorage.getItem("seenNotification")
    );
    return notifications.some((ele) => !seenNotification.includes(ele._id));
  };

  const submitReview = async () => {
    let project = {};
    LoaderShow();
    if (validateForm()) {
      try {
        const res = await postAPI(
          "project/porjectById",
          {
            projectId: selectedNotification.data.projectId,
          },
          GetUserDetails().token
        );
        if (res.statusCode == 200) {
          project = res.data;
        }
        const userId = GetUserDetails().userId;

        const dataToSend = {
          userId: project.user._id,
          reviewerId: userId,
          projectId: project?._id,
          rating: valuerating,
          comment: text.textReview,
        };

        const response = await postAPI("review/create", dataToSend);
        if (response.statusCode === 200) {
          toast.success(t(response.message));
          setIsFinishModalOpen(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    LoaderHide();
  };

  const handleStar = (event) => {
    const value = event.target.value;
    setValueRating(value);
    setErrorMessages({
      ...errorMessages,
      valuerating:
        event?.target?.value?.length === 0 ? t("PleaseGiveStar") : "",
    });
  };

  const handleTextArea = (event) => {
    const value = event.target.value;
    setText({ ...text, textReview: value });
    setErrorMessages({
      ...errorMessages,
      textReview: text.textReview.length < 5 ? t("ReviewShouldbe5") : "",
    });
  };

  const showReview = (notification) => {
    setselectedNotification(notification);
    setIsFinishModalOpen(true);
  };

  const getNotifications = async () => {
    try {
      const res = await getAPI("notification/get", GetUserDetails().token);
      if (res?.statusCode == 200) {
        setNotifications(res?.data);
        localStorage.setItem(
          "seenNotification",
          JSON.stringify(res?.data?.map((ele) => ele._id))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openProfileMenu = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setProfileMenuAnchor(null);
  };

  const toggleNotifications = () => {
    setNotificationOpen((dt) => !dt);
  };

  const closeNotifications = () => {
    setNotificationOpen(false);
  };

  const logoutProfile = async () => {
    setProfileMenuAnchor(null);
    try {
      postAPI("user/logout", {}, GetUserDetails().token);
      await signOut();
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
    navigate("/home");
    localStorage.removeItem("loginData");
    localStorage.removeItem("notif-token");
    localStorage.removeItem("seenNotification");
    sessionStorage.removeItem("userData");
  };

  function haveCommonElement(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.some((element) => arr2.includes(element));
  }

  const getUserDetails = async () => {
    try {
      const token = GetUserDetails().token;
      const oldRole = GetUserDetails().role;
      const response = await getAPI("user/getdetails", token);
      if (response.statusCode === 200) {
        setUserDetails(response.data);
        const roleIds = response?.data?.role?.map((ele) => ele?._id);
        if (!haveCommonElement(roleIds, oldRole)) {
          const userDetails = GetUserDetails();
          userDetails.role = roleIds;
          sessionStorage.removeItem("hasNavigated");
          localStorage.setItem("loginData", JSON.stringify(userDetails));
          window.location.reload();
        }
      }
    } catch (error) {
      // handle error
    }
  };

  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const handleMarkAllSeen = () => {
    // Logic to mark all notifications as seen (update state or API call)
    // For demo, we'll clear the notifications array
    setNotifications([]);
  };

  const LogoutModal = ({ open, handleClose }) => {
    const handleLogout = async () => {
      try {
        await postAPI("user/logout", {}, GetUserDetails().token);
        await signOut();
      } catch (err) {
        console.log(err);
      }
      localStorage.removeItem("notif-token");
      localStorage.removeItem("loginData");
      localStorage.removeItem("seenNotification");
      sessionStorage.removeItem("userData");
      navigate("/");
      window.location.reload();
      handleClose();
    };

    const handleCloseModal = () => {
      handleClose(); // Close the modal after logout
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="logout-modal"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 510 : "75%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 4,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            onClick={handleClose}
          >
            <img src={close} alt="Close" />
          </IconButton>
          <Box>
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                py: 1,
              }}
            >
              <img className="deleteVec" src={logoutVect} alt="Logout" />
            </Box>
            <Typography
              id="logout-modal-description"
              className="employeeHead1"
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 32,
                textAlign: "center",
                py: 2,
                pb: 1,
              }}
              gutterBottom
            >
              {t("Are")}
            </Typography>
            <Typography
              id="logout-modal-description"
              className="employeeHead"
              variant="p"
              sx={{ fontSize: 18, textAlign: "center", display: "block" }}
              gutterBottom
            >
              {t("SessionExpires")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 4 }}>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 4,
                py: 1,
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={handleLogout}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 20,
                textTransform: "capitalize",
                px: 6,
                py: 1,
              }}
              variant="contained"
            >
              {t("Yes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  return (
    <section className="DashboardHeader">
      <Modal
        open={isFinishModalOpen}
        onClose={() => {
          setIsFinishModalOpen(false);
          setselectedNotification({});
        }}
        aria-labelledby="finish-modal-title"
        aria-describedby="finish-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "90%" : isDesktop ? 550 : "75%", // Adjust width for mobile and desktop devices
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              py: 2,
              px: 3,
              alignItems: "center",
              borderBottom: 1,
              borderColor: "#d8d8d8",
            }}
          >
            <Typography variant="h6">{t("LeaveAReview")}</Typography>
            {/* <IconButton onClick={handleCloseRating}>
                <img src={close} alt="db" />
              </IconButton> */}
          </Box>
          <Box sx={{ px: 4 }}>
            <Typography
              variant="p"
              sx={{
                textAlign: "center",
                fontSize: 17,
                color: "#000",
                display: "block",
                py: 2,
              }}
            >
              {t("HowToYouRating")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Rating
              name="simple-controlled"
              value={valuerating}
              onChange={handleStar}
              sx={{ fontSize: "36px" }} // Adjust the font size as needed
            />
          </Box>
          {errorMessages.valuerating && (
            <Typography
              variant="p"
              sx={{ color: "red", textAlign: "center", marginLeft: "32px" }}
            >
              {errorMessages.valuerating}
            </Typography>
          )}
          <Grid container sx={{ px: 4 }}>
            <Grid item xs={12}>
              <Box>
                <FormLabel
                  sx={{
                    display: "block",
                    textAlign: "start",
                    py: 2,
                    fontWeight: "600",
                    color: "#000",
                    fontSize: 17,
                  }}
                ></FormLabel>
                <Textarea
                  value={text.textReview}
                  onChange={handleTextArea}
                  sx={{
                    width: "100%!important",
                    border: 1,
                    borderColor: "#cbcbcb",
                    borderRadius: 1,
                  }}
                  minRows={6}
                  maxRows={6}
                  placeholder={t("WriteAReview")}
                />
                {errorMessages.textReview && (
                  <Typography variant="p" sx={{ color: "red" }}>
                    {errorMessages.textReview}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              my: 4,
              px: 4,
            }}
          >
            <Button
              fullWidth={true}
              variant="contained"
              onClick={submitReview}
              sx={{
                backgroundColor: "#0B3013!important",
                fontSize: 16,
                borderRadius: 3,
                textTransform: "capitalize",

                py: 1,
              }}
            >
              {t("SubmitReview")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <LogoutModal
        open={logoutModalOpen}
        handleClose={handleCloseLogoutModal}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          borderBottom: 1,
          borderColor: "#000",
          py: 0.4,
        }}
      >
        <Toolbar sx={{ py: 0.5, boxShadow: "none" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={Logo} alt="logo" />
              <Typography
                className="dNoneRespo"
                variant="h4"
                sx={{ px: 3, fontSize: 28 }}
              >
                Workwavehub
              </Typography>
              <Box
                sx={{ cursor: "pointer", px: 1, display: { md: "none" } }}
                onClick={handleDrawerToggle}
              >
                <img src={toggle} alt="toggle" />
              </Box>
            </Box>
          </Box>
        </Toolbar>
        <Box
          className="prZero"
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            paddingRight: 3,
          }}
        >
          <Box
            sx={{ px: 2, position: "relative" }}
            onClick={toggleNotifications}
          >
            {notifications.length > 0 && hasUnseenNotification() ? (
              <div
                style={{
                  position: "absolute",
                  right: "13px",
                  top: "-3px",
                  width: "8px",
                  height: "8px",
                  borderRadius: "100%",
                  backgroundColor: "red",
                }}
              ></div>
            ) : (
              ""
            )}
            <img src={Not} alt="notification" />
          </Box>
          {/* <Box className="dNoneRespo" sx={{ px: 2 }}> */}
            <CountryFlag />
          {/* </Box> */}
          <Box sx={{ px: 1 }}>
            <IconButton onClick={openProfileMenu}>
              <Avatar
                src={userDetails?.profileImageUrl}
                sx={{ width: 50, height: 50, border: 1, borderColor: "#ccc" }}
              />
            </IconButton>
            <Menu
              anchorEl={profileMenuAnchor}
              open={Boolean(profileMenuAnchor)}
              onClose={closeProfileMenu}
            >
              <MenuItem onClick={closeProfileMenu}>
                {userDetails?.firstName}
              </MenuItem>
              <MenuItem onClick={(ev) => setLogoutModalOpen(true)}>
                {t("Logout")}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      {/* Notification Panel */}
      {notificationOpen && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 998,
            }}
            onClick={(ev) => setNotificationOpen(false)}
          ></div>
          <Box
            id="notif-dialog"
            ref={notificationPanelRef} // Ref to handle click outside
            sx={{
              position: "absolute",
              top: 80,
              right: 20,
              width: 350,
              maxWidth: "80vw",
              bgcolor: "#fff",
              boxShadow: 4,
              borderRadius: 1,
              zIndex: 999,
              p: 2,
              px: 0,
            }}
          >
            <Box
              sx={{
                mb: 2,
                maxHeight: "400px",
                overflow: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: 1,
                  borderColor: "#DFDCD8",
                  px: 2,
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    color: "#000",
                    fontSize: 20,
                    py: 1,
                    pb: 2,
                    px: 2,
                    display: "block",
                  }}
                >
                  {t("Notifications")}
                </Typography>
                <LoopIcon
                  onClick={getNotifications}
                  sx={{ px: 1, color: "#000", mt: -1, cursor: "pointer" }}
                />
              </Box>
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <a
                    href={notification?.data ? getUrl(notification?.data) : "#"}
                    style={{ textDecoration: "none" }}
                    onClick={(ev) =>
                      getUrl(notification?.data) == "#"
                        ? showReview(notification)
                        : ""
                    }
                  >
                    <Box
                      key={notification._id}
                      sx={{
                        py: 1,
                        borderBottom: 1,
                        borderColor: "#DFDCD8",
                        px: 2,
                        backgroundColor: "#ffffff",
                        cursor: "pointer", // Setting different background color based on read status
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          maxWidth: "100%",
                          wordWrap: "break-word",
                        }}
                        variant="body1"
                      >
                        {notification.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: "text.secondary" }}
                      >
                        {notification.body}
                      </Typography>
                    </Box>
                  </a>
                ))
              ) : (
                <Box
                  sx={{
                    py: 1,
                    borderBottom: 1,
                    borderColor: "#DFDCD8",
                    px: 2,
                    backgroundColor: "#ffffff",
                    cursor: "pointer", // Setting different background color based on read status
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      maxWidth: "100%",
                      wordWrap: "break-word",
                    }}
                    variant="body1"
                  >
                    {t("noNotiffound")}
                  </Typography>
                </Box>
              )}
            </Box>
            {/* <Box sx={{ px: 2, display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleMarkAllSeen}
              sx={{
                backgroundColor: "#0B3013",
                borderRadius: 30,
                color: "#fff",
                px: 3,
                py: 1,
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#0B3013", // Change hover background color here
                },
              }}
            >
              Mark all as seen
            </Button>
          </Box> */}
          </Box>
        </>
      )}
    </section>
  );
}
